<template>
  <div id="TTTQMForm">
    <div class="mb-4">
      <div class="row align-items-center mb-2">
        <div class="col-6">
          <div class="robo-14-400">
            <label class="me-1">{{ $t("mdt_room.lbl_patients_name") }}:</label>
            <span class="fw-bold">{{ form.name }}</span>
          </div>
        </div>
        <div class="col-4">
          <div class="robo-14-400">
            <label class="me-1">{{ $t("mdt_room.lbl_age") }}:</label>
            <span class="fw-bold">
              {{
                curr_disease &&
                curr_disease.patient_birthday &&
                mdtUtils.getAges(new Date(curr_disease.patient_birthday))
              }}
            </span>
          </div>
        </div>
        <div class="col-2">
          <div class="robo-14-400">
            <label class="me-1">{{ $t("mdt_room.lbl_genders") }}:</label>
            <span class="fw-bold">{{ getGender(form.sex) }}</span>
          </div>
        </div>
      </div>
      <div class="row align-items-center mb-2">
        <div class="col-6">
          <div class="robo-14-400">
            {{ $t("mdt_room.lbl_address") }}: {{ form.address }}
          </div>
        </div>
        <div class="col-6">
          <div class="d-flex align-items-center">
            <label class="robo-14-400 mb-0 me-2"
              >{{ $t("mdt_room.lbl_faculty_treatment") }}:</label
            >
            <input
              :readonly="hasSigned"
              type="text"
              class="border fs-14 form-control bg-white"
              v-model="form.dept"
            />
          </div>
        </div>
      </div>
      <div class="row align-items-center mb-2">
        <div class="col-6">
          <div class="overflow-hidden">
            <label class="float-start robo-14-400 me-1 mb-0"
              >{{ $t("mdt_room.lbl_diagnose") }}:</label
            >
            <div class="robo-14-400" v-html="form && form.diagnostic"></div>
          </div>
        </div>
        <div class="col-6">
          <div class="d-flex align-items-center">
            <label class="robo-14-400 mb-0 me-2 whitespace-nowrap"
              >{{ $t("mdt_room.lbl_mdt_at") }}:</label
            >
            <input
              :readonly="hasSigned"
              type="datetime-local"
              class="border fs-14 form-control bg-white"
              v-model="form.mdt_time"
            />
          </div>
        </div>
      </div>
      <!-- <div class="row align-items-center mb-2">
        <div class="col-6">
          <div class="fs-14 mb-2">
            {{ $t('mdt_room.lbl_mems_preside') }}:
            <span>{{ form.preside }}</span>
          </div>
        </div>
        <div class="col-6">
          <div class="fs-14 mb-2">
            {{ $t('mdt_room.lbl_mems_type_secretary') }}:
            <span>{{ form.secretary }}</span>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12">
          <div
            class="fs-14 text-nowrap mb-0 me-2"
          >{{ $t('mdt_room.lbl_participant') }}:</div>
          <div>
            <textarea class="form-control fs-14 bg-white border" v-model="form.members" rows="2"></textarea>
          </div>
        </div>
      </div>-->
      <div class="row mb-2">
        <div class="col-4 mb-2">
          <div class="fs-14 mb-0 me-2 text-nowrap">
            {{ $t("mdt_room.lbl_admission_hospital_number") }}:
          </div>
          <div>
            <input
              :readonly="hasSigned"
              type="text"
              class="border fs-14 form-control bg-white"
              v-model="form.code"
            />
          </div>
        </div>
        <div class="col-8">
          <div class="fs-14 mb-0 me-2 text-nowrap">
            {{ $t("mdt_room.lbl_report_treatment_time") }}:
          </div>
          <div class="row">
            <div class="col-6">
              <div>
                <input
                  :readonly="hasSigned"
                  type="datetime-local"
                  class="border fs-14 form-control bg-white"
                  v-model="form.treatment_time_start"
                />
              </div>
            </div>
            <div class="col-6">
              <div>
                <input
                  :readonly="hasSigned"
                  type="datetime-local"
                  class="border fs-14 form-control bg-white"
                  v-model="form.treatment_time_end"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="d-flex align-items-center mb-2">
      <p class="robo-14-400 mb-0 me-2">Hội chẩn lúc:</p>
      <input type="datetime-local" class="border" v-model="form.mdt_time" />
    </div>-->
    <div class="m-0 mb-4">
      <div>
        <label class="robo-16-500 fw-bold text-uppercase">
          I. {{ $t("mdt_room.lbl_treatment_his") }}:
        </label>
        <vue-editor
          :disabled="hasSigned"
          v-model="form.medical_history"
          :editorToolbar="customToolbar"
        ></vue-editor>
      </div>
    </div>
    <div class="m-0 mb-4">
      <div>
        <div class="robo-16-500 fw-bold">
          <span class="text-uppercase me-1">
            {{ $t("mdt_room.lbl_report_treatment_conclusion") }}:
          </span>
        </div>
        <vue-editor
          :disabled="hasSigned"
          v-model="form.conclusion"
          :editorToolbar="customToolbar"
        ></vue-editor>
      </div>
    </div>
    <div class="robo-16-500 fw-bold text-uppercase">
      <p>II. {{ $t("mdt_room.lbl_laboratory") }}</p>
    </div>
    <div class="robo-16-500">
      <p class="mb-0">1. {{ $t("mdt_room.lbl_blood_test") }}</p>
    </div>
    <div class="mb-3 row">
      <div class="col-6">
        <div class="form-group">
          <label class="robo-14-500 fw-bold">BC</label>
          <input
            :readonly="hasSigned"
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.test_bc"
          />
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label class="robo-14-500 fw-bold">Ure</label>
          <input
            :readonly="hasSigned"
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.test_ure"
          />
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label class="robo-14-500 fw-bold">Bilirubin TP</label>
          <input
            :readonly="hasSigned"
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.test_bilirubin_tp"
          />
        </div>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-6">
        <div class="form-group">
          <label class="robo-14-500 fw-bold">HST</label>
          <input
            :readonly="hasSigned"
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.test_hst"
          />
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label class="robo-14-500 fw-bold">Creatinin</label>
          <input
            :readonly="hasSigned"
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.test_creatinin"
          />
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label class="robo-14-500 fw-bold">Bilirubin TP</label>
          <input
            :readonly="hasSigned"
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.test_bilirubin_tt"
          />
        </div>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-6">
        <div class="form-group">
          <label class="robo-14-500 fw-bold">HC</label>
          <input
            :readonly="hasSigned"
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.test_hc"
          />
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label class="robo-14-500 fw-bold">Glucose</label>
          <input
            :readonly="hasSigned"
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.test_glucose"
          />
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label class="robo-14-500 fw-bold">GOT</label>
          <input
            :readonly="hasSigned"
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.test_got"
          />
        </div>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-3">
        <div class="form-group">
          <label class="robo-14-500 fw-bold">HCT</label>
          <input
            :readonly="hasSigned"
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.test_hct"
          />
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label class="robo-14-500 fw-bold">{{
            $t("mdt_room.lbl_blood")
          }}</label>
          <input
            :readonly="hasSigned"
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.test_blood"
          />
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label class="robo-14-500 fw-bold">Protit</label>
          <input
            :readonly="hasSigned"
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.test_protit"
          />
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label class="robo-14-500 fw-bold">GPT</label>
          <input
            :readonly="hasSigned"
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.test_gpt"
          />
        </div>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-3">
        <div class="form-group">
          <label class="robo-14-500 fw-bold">TC</label>
          <input
            :readonly="hasSigned"
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.test_tc"
          />
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label class="robo-14-500 fw-bold">PT%</label>
          <input
            :readonly="hasSigned"
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.test_pt"
          />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label class="robo-14-500 fw-bold">Cholesterol</label>
          <input
            :readonly="hasSigned"
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.test_cholesterol"
          />
        </div>
      </div>
    </div>
    <div class="mb-3 row">
      <!-- <div class="col-3">
        <div class="form-group">
          <label class="robo-14-500 fw-bold">Hematocrit</label>
          <input type="text" class="form-control mt-1" placeholder v-model="form.test_hematocrit" />
        </div>
      </div>-->
      <div class="col-3">
        <div class="form-group">
          <label class="robo-14-500 fw-bold">{{
            $t("mdt_room.lbl_basic_coagulation")
          }}</label>
          <input
            :readonly="hasSigned"
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.test_blood_basic"
          />
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label class="robo-14-500 fw-bold">Quick</label>
          <input
            :readonly="hasSigned"
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.test_quick"
          />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label class="robo-14-500 fw-bold">GGT</label>
          <input
            :readonly="hasSigned"
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.test_ggt"
          />
        </div>
      </div>
    </div>
    <div class="m-0 mb-3">
      <div class="form-group">
        <label class="robo-16-500">2. {{ $t("mdt_room.lbl_urine") }}</label>
        <input
          :readonly="hasSigned"
          type="text"
          class="form-control mt-1"
          placeholder
          v-model="form.urine"
        />
      </div>
    </div>
    <div class="m-0 mb-3">
      <div class="form-group">
        <label class="robo-16-500"
          >3. {{ $t("mdt_room.lbl_chest_xray") }}</label
        >
        <input
          :readonly="hasSigned"
          type="text"
          class="form-control mt-1"
          placeholder
          v-model="form.xray"
        />
      </div>
    </div>
    <div class="m-0 mb-3">
      <div class="form-group">
        <label class="robo-16-500"
          >4. {{ $t("mdt_room.lbl_ellectrocardiogram") }}</label
        >
        <input
          :readonly="hasSigned"
          type="text"
          class="form-control mt-1"
          placeholder
          v-model="form.electrocar"
        />
      </div>
    </div>
    <div class="m-0 mb-3">
      <div class="form-group">
        <label class="robo-16-500"
          >5. {{ $t("mdt_room.lbl_ultrasound") }}</label
        >
        <input
          :readonly="hasSigned"
          type="text"
          class="form-control mt-1"
          placeholder
          v-model="form.ultrasound"
        />
      </div>
    </div>
    <div class="m-0 mb-4">
      <div class="form-group">
        <label class="robo-16-500"
          >6. {{ $t("mdt_room.lbl_endoscopic") }}</label
        >
        <input
          :readonly="hasSigned"
          type="text"
          class="form-control mt-1"
          placeholder
          v-model="form.endoscopy"
        />
      </div>
    </div>
    <div class="robo-16-500 fw-bold text-uppercase">
      <p>III. {{ $t("mdt_room.lbl_preparationsurgical") }}</p>
    </div>
    <div class="m-0 mb-3">
      <div class="form-group">
        <label class="robo-16-500"
          >1. {{ $t("mdt_room.lbl_test_drug_reaction") }}</label
        >
        <input
          :readonly="hasSigned"
          type="text"
          class="form-control mt-1"
          placeholder
          v-model="form.drug_reaction"
        />
      </div>
    </div>
    <div class="m-0 mb-3">
      <div class="form-group">
        <label class="robo-16-500"
          >2. {{ $t("mdt_room.lbl_preoperative_antibiotics") }}</label
        >
        <input
          :readonly="hasSigned"
          type="text"
          class="form-control mt-1"
          placeholder
          v-model="form.antibiotics"
        />
      </div>
    </div>
    <div class="m-0 mb-3">
      <div class="form-group">
        <label class="robo-16-500"
          >3. {{ $t("mdt_room.lbl_surgical_method") }}</label
        >
        <input
          :readonly="hasSigned"
          type="text"
          class="form-control mt-1"
          placeholder
          v-model="form.surgery_method"
        />
      </div>
    </div>
    <div class="m-0 mb-3">
      <div class="form-group">
        <label class="robo-16-500"
          >4. {{ $t("mdt_room.lbl_anaesthetic_method") }}</label
        >
        <input
          :readonly="hasSigned"
          type="text"
          class="form-control mt-1"
          placeholder
          v-model="form.anaesthetic_method"
        />
      </div>
    </div>
    <div class="m-0 mb-3">
      <div class="form-group">
        <label class="robo-16-500"
          >5. {{ $t("mdt_room.lbl_surgery_team") }}</label
        >
        <input
          :readonly="hasSigned"
          type="text"
          class="form-control mt-1"
          placeholder
          v-model="form.surgical_team"
        />
      </div>
    </div>
    <div class="m-0 mb-3">
      <div class="form-group">
        <label class="robo-16-500"
          >6. {{ $t("mdt_room.lbl_anaesthetic_team") }}</label
        >
        <input
          :readonly="hasSigned"
          type="text"
          class="form-control mt-1"
          placeholder
          v-model="form.anaesthetic_team"
        />
      </div>
    </div>
    <div class="m-0 mb-3">
      <div class="form-group">
        <label class="robo-16-500">7. {{ $t("mdt_room.lbl_pathology") }}</label>
        <input
          :readonly="hasSigned"
          type="text"
          class="form-control mt-1"
          placeholder
          v-model="form.anatomic"
        />
      </div>
    </div>
    <div class="mb-3 row">
      <div class="form-group col-6">
        <label class="robo-16-500"
          >8. {{ $t("mdt_room.lbl_blood_plan") }}</label
        >
        <input
          :readonly="hasSigned"
          type="text"
          class="form-control mt-1"
          placeholder
          v-model="form.blood_plan"
        />
      </div>
      <div class="form-group col-6">
        <label class="robo-16-500">{{
          $t("mdt_room.lbl_expected_date_of_surgery")
        }}</label>
        <input
          :readonly="hasSigned"
          type="date"
          class="form-control mt-1"
          placeholder
          v-model="form.surgery_date"
        />
      </div>
    </div>
    <div class="m-0 mb-4">
      <div class="form-group">
        <label class="robo-16-500"
          >9. {{ $t("mdt_room.lbl_prognostic") }}</label
        >
        <input
          :readonly="hasSigned"
          type="text"
          class="form-control mt-1"
          placeholder
          v-model="form.prognostic"
        />
      </div>
    </div>
    <div class="robo-16-500 fw-bold text-uppercase">
      <p>IV. {{ $t("mdt_room.lbl_minute_signing") }}</p>
    </div>
    <div class="row mb-3">
      <div class="col-4">
        <div class="form-group">
          <label class="robo-16-500">{{
            $t("mdt_room.lbl_esthesiology_dept_mng")
          }}</label>
          <el-select
            class="w-full mt-2"
            v-model="form.gm_sur_anes_id"
            placeholder=""
            :disabled="hasSigned"
            @change="(item) => handleChangeDoctorType(item, 'gm_sur_anes_name')"
          >
            <el-option
              v-for="item in doctors"
              :key="item.id"
              :label="item.name"
              :value="item.user_id"
            >
            </el-option>
          </el-select>
          <!-- <input
            :readonly="hasSigned"
            type="text"
            class="form-control mt-1"
            placeholder
            v-model="form.gm_sur_anes_name"
          /> -->
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label class="robo-16-500">{{
            $t("mdt_room.lbl_surgery_dept_mng")
          }}</label>
          <el-select
            class="w-full mt-2"
            v-model="form.gm_surgery_id"
            placeholder=""
            :disabled="hasSigned"
            @change="(item) => handleChangeDoctorType(item, 'gm_surgery_name')"
          >
            <el-option
              v-for="item in doctors"
              :key="item.id"
              :label="item.name"
              :value="item.user_id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label class="robo-16-500">{{
            $t("mdt_room.lbl_attending_doctor")
          }}</label>
          <el-select
            class="w-full mt-2"
            v-model="form.treatment_doctor_id"
            placeholder=""
            :disabled="hasSigned"
            @change="(item) => handleChangeDoctorType(item, 'doctor_name')"
          >
            <el-option
              v-for="item in doctors"
              :key="item.id"
              :label="item.name"
              :value="item.user_id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div
      class="my-3 float-end d-flex gap-2"
      :class="
        curr_disease.operation && curr_disease.operation.sign
          ? 'justify-content-between align-items-center float-none'
          : ''
      "
    >
      <div
        class="d-flex flex-wrap fs-14"
        v-if="curr_disease.operation && curr_disease.operation.sign"
      >
        <div>{{ curr_disease.operation.signer_name }} đã ký -&nbsp;</div>
        <div>{{ signAt }}</div>
      </div>
      <button
        v-if="
          curr_disease.operation &&
          !curr_disease.operation.sign &&
          curr_disease.status === 2
        "
        :disabled="!isPresident || loading || !curr_disease.operation"
        class="btn-action btn bg-pri bd-pri text-white px-5"
        @click="handleSignTTTQM"
      >
        <span
          class="spinner-border spinner-border-sm me-2"
          role="status"
          v-if="loading"
        ></span>
        <span v-else>{{ $t("mdt_room.btn_sign") }}</span>
      </button>
      <button
        v-if="curr_disease.status < 2"
        :disabled="loading"
        class="btn btn-action btn-primary bg-pri bd-pri px-5"
        @click="submit()"
      >
        <span
          class="spinner-border spinner-border-sm me-2"
          role="status"
          v-if="loading"
        ></span>
        <span v-else>{{ $t("mdt_room.btn_save") }}</span>
      </button>
      <!-- <button
        class="btn btn-primary bg-pri bd-pri px-5"
        @click="submit()"
        :disabled="!isMaster || loading"
      >{{ $t('mdt_room.btn_save') }}</button>-->
    </div>
    <TTTQMTemplate
      :form="form"
      :room_info="room_info"
      :mdt_disease="curr_disease"
      :save_pdf="save_pdf"
      @onSubmiting="onSubmiting"
    />
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import TTTQMTemplate from '../../templates/HoiChan/TTTQMTemplate.vue'
import mdtUtils from '../../utils/mdtUtils'
import appUtils from '../../utils/appUtils'
import { DOCUMENT_SIGN_TYPES, SIGN_MDT_DISEASE } from '../../utils/constants'
import { mapState } from 'vuex'

export default {
  name: 'TTTQMForm',
  components: { TTTQMTemplate, VueEditor },
  props: ['curr_disease', 'room_info', 'present_language', 'checkin_members'],
  data () {
    const defaultForm = {
      name: '',
      age: null,
      sex: '',
      address: '',
      code: '',
      dept: '',
      diagnostic: '',
      mdt_time: '',
      treatment_progress: '',
      treatment_time: '',
      medical_history: '',
      test_bc: '',
      test_ure: '',
      test_bilirubin_tp: '',
      test_bilirubin_tt: '',
      test_hst: '',
      test_creatinin: '',
      test_hc: '',
      test_glucose: '',
      test_got: '',
      test_hct: '',
      test_blood: '',
      test_protit: '',
      test_gpt: '',
      test_tc: '',
      test_pt: '',
      test_cholesterol: '',
      urine: '',
      xray: '',
      electrocar: '',
      ultrasound: '',
      endoscopy: '',
      drug_reaction: '',
      antibiotics: '',
      surgery_desc: '',
      method: '',
      surgical_team: '',
      blood_plan: '',
      surgery_date: window.moment().format('YYYY-MM-DD'),
      prognostic: '',
      gm_sur_anes_name: '',
      gm_surgery_name: '',
      doctor_name: '',
      anaesthetic_method: '',
      anaesthetic_team: '',
      anatomic: '',
      preside: '',
      secretary: '',
      members: '',
      treatment_time_start: '',
      treatment_time_end: '',
      conclusion: '',
      test_hematocrit: '',
      test_blood_basic: '',
      test_quick: '',
      test_ggt: '',
      admission_room: '',
      admission_bed: '',
      surgery_method: '',
      treatment_doctor_id: null,
      gm_sur_anes_id: null,
      gm_surgery_id: null
    }

    return {
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['code-block', 'link'],
        [
          {
            align: ''
          },
          {
            align: 'center'
          },
          {
            align: 'right'
          },
          {
            align: 'justify'
          }
        ]
      ],
      defaultForm,
      form: defaultForm,
      save_pdf: 0,
      doctor_id: null,
      loading: false,
      dtr_member: null,
      mdtUtils,
      doctors: []
    }
  },
  mounted () {
    this.doctor_id = this.$user?.doctor?.id
    this.getDoctors()
    this.handlerMemberstring()
    this.handlerRoomInfo()
    this.handleGetTTTQM(this.curr_disease)
    if (this.room_info?.clinic && !this.form.dept) {
      this.form.dept = this.room_info.clinic.name
    }
  },
  watch: {
    present_language () {
      this.handleGetTTTQM(this.curr_disease)
    },
    curr_disease: {
      handler (d) {
        this.getDoctors()
        this.handleGetTTTQM(d)
      },
      deep: true
    },
    room_info (r) {
      if (r.clinic && !this.form.dept) {
        this.form.dept = r.clinic.name
      }
      this.handlerRoomInfo()
    },
    checkin_members (members) {
      this.handlerMemberstring()
    },
    person (data) {
      if (data) {
        this.form.admission_room = data.admission_room || ''
        this.form.admission_bed = data.admission_bed || ''
      } else {
        this.form.admission_room = ''
        this.form.admission_bed = ''
      }
    }
  },
  computed: {
    ...mapState({
      person: (state) => state.person
    }),
    isMaster () {
      return (
        this.dtr_member?.role === 1 ||
        this.dtr_member?.role === 4 ||
        this.doctor_id === this.curr_disease?.doctor_id
      )
    },
    langId () {
      return appUtils.getLangId(this.present_language)
    },
    signAt () {
      return window
        .moment(this.curr_disease?.operation.sign?.sign_at)
        .format('HH:mm - DD/MM/YYYY')
    },
    isPresident () {
      return this.doctor_id === this.room_info?.preside?.doctor_id
    },
    hasSigned () {
      return (
        Boolean(this.curr_disease) &&
        Boolean(
          this.curr_disease.concluding?.sign ||
            this.curr_disease.operation?.sign ||
            this.curr_disease.status === 2
        )
      )
    }
  },
  methods: {
    handlerMemberstring () {
      const members = this.checkin_members
      if (members && members.count && !this.form.members) {
        let string = ''
        members.data.forEach((m) => {
          if (m.status === 1) {
            string += m?.doctor?.name + ', '
          }
        })
        this.form.members = string
      }
    },
    handlerRoomInfo () {
      if (!this.room_info) return
      this.dtr_member = this.room_info?.members?.find(
        (m) => m.doctor_id === this.doctor_id
      )
      this.pre = this.room_info?.members?.find((m) => m.role === 1)
      this.form.preside = this.pre?.doctor?.name
    },
    processDisease (d) {
      console.log({ d })
      this.form = this.defaultForm

      if (d) {
        // const diseaseCurrentLangData = d?.multilang?.find(
        //   item => item.lang === this.langId
        // )

        // const concludingCurrentLangData = d?.concluding?.multilang?.find(
        //   item => item.lang === this.langId
        // )
        const diseaseCurrentLangData = d?.multilang?.find(
          (item) => item.lang === 1
        )

        const concludingCurrentLangData = d?.concluding?.multilang?.find(
          (item) => item.lang === 1
        )
        // const concludingCurrentLangData = 1

        if (d?.medical_record) {
          this.form.code = d?.medical_record?.ref_id
        }

        if (d.operation) {
          const { ...newOperation } = d.operation
          this.form = {
            ...this.form,
            ...newOperation,
            // age: this.getAges(d.patient_birthday) || null,
            code: newOperation.code || '',
            diagnostic:
              newOperation.diagnostic ||
              (diseaseCurrentLangData &&
                diseaseCurrentLangData?.patient_diagnostic) ||
              d.patient_diagnostic ||
              '',
            mdt_time: newOperation.mdt_time
              ? appUtils.formatInputDateTime(newOperation.mdt_time)
              : appUtils.formatInputDateTime(this.room_info.start_time),
            surgery_date: newOperation.surgery_date
              ? window
                .moment(newOperation.surgery_date, 'YYYY-MM-DDTHH:mm:ssZ')
                .format('YYYY-MM-DD')
              : window.moment().format('YYYY-MM-DD'),
            preside:
              newOperation?.preside ||
              d?.preside ||
              this.pre?.doctor?.name ||
              '',
            secretary: newOperation?.doctor_name || d.doctor?.name || '',
            members: newOperation?.members || this.form.members || '',
            treatment_time_start:
              appUtils.formatInputDateTime(
                newOperation?.treatment_time_start
              ) ||
              appUtils.formatInputDateTime(d?.treatment_time_start) ||
              '',
            treatment_time_end:
              appUtils.formatInputDateTime(newOperation?.treatment_time_end) ||
              appUtils.formatInputDateTime(d?.treatment_time_end) ||
              ''
            // gm_sur_anes_id:
          }
          console.log({ form: this.form })
        } else {
          this.form = {
            ...this.defaultForm,
            name: d.patient_name,
            // age: this.getAges(d.patient_birthday) || null,
            sex: d.patient_gender,
            address: d.patient_address,
            code:
              d?.medical_record?.ref_id || d?.concluding?.profile_code || '',
            diagnostic:
              (diseaseCurrentLangData &&
                diseaseCurrentLangData?.patient_diagnostic) ||
              d.patient_diagnostic ||
              '',
            medical_history:
              (concludingCurrentLangData &&
                concludingCurrentLangData.treatment_progress) ||
              d.patient_diseases_current_cure ||
              (diseaseCurrentLangData &&
                diseaseCurrentLangData.patient_diseases_current_cure) ||
              '',
            conclusion:
              (concludingCurrentLangData &&
                concludingCurrentLangData.conclusion) ||
              d.conclusion ||
              (diseaseCurrentLangData && diseaseCurrentLangData.conclusion) ||
              '',
            mdt_time: d.mdt_date
              ? appUtils.formatInputDateTime(d.mdt_date)
              : appUtils.formatInputDateTime(),
            dept: this.form.dept || this.room_info?.clinic?.name || '',
            surgery_date: this.moment().format('YYYY-MM-DD'),
            doctor_name: d.doctor?.name,
            preside: d?.preside || this.pre?.doctor?.name || '',
            secretary: d.doctor?.name || '',
            members: this.form.members || '',
            treatment_time_start: d.admission_date
              ? appUtils.formatInputDateTime(d.admission_date)
              : '',
            treatment_time_end: d.mdt_date
              ? appUtils.formatInputDateTime(d.mdt_date)
              : ''
          }
        }
      }
    },
    submit () {
      this.loading = true
      this.save_pdf++
    },
    onSubmiting (base64) {
      if (!this.curr_disease) return
      if (this.curr_disease.operation) {
        this.updateMdtDiseaseOperation(base64)
      } else {
        this.createMdtDiseaseOperation(base64)
      }
    },
    async savePdfToS3 (base64, operationId = this.curr_disease?.operation?.id) {
      if (!base64 || !operationId) return

      try {
        this.loading = true

        const payload = {
          content: base64,
          fileName:
            `${appUtils.convertSlug(
              `${this.$t('TTTQM')} ${operationId} ${window
                .moment(this.curr_disease?.operation?.created_at)
                .format('YYYY-MM-DD')}`
            )}_` || 'operation_',
          path: 'room_disease_operation',
          type: DOCUMENT_SIGN_TYPES.CODE.DISEASE_OPERATION,
          type_id: operationId,
          uuid: ''
        }

        const res = await this.$rf
          .getRequest('DoctorRequest')
          .printDocument(payload)

        if (res?.data) {
          // this.loading = false
          // window.open(res.data?.documentLink, '_blank', 'noreferrer')
        }
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    async createMdtDiseaseOperation (base64 = null) {
      const self = this
      const params = { ...this.form }
      params.mdt_room_disease_id = self.curr_disease.id
      params.mdt_time = params.mdt_time
        ? this.moment(params.mdt_time).format('YYYY-MM-DDTHH:mm:ssZ')
        : null
      params.surgery_date = params.surgery_date
        ? this.moment(params.surgery_date)
          .add(7, 'hours')
          .format('YYYY-MM-DDTHH:mm:ssZ')
        : null

      params.treatment_time = params.treatment_time
        ? this.moment(params.treatment_time).format('YYYY-MM-DDTHH:mm:ssZ')
        : null

      params.treatment_time_end = params.treatment_time_end
        ? this.moment(params.treatment_time_end).format('YYYY-MM-DDTHH:mm:ssZ')
        : null

      params.treatment_time_start = params.treatment_time_start
        ? this.moment(params.treatment_time_start).format(
          'YYYY-MM-DDTHH:mm:ssZ'
        )
        : null
      // params.mdt_time = this.moment(params.mdt_time).format('HH:mm DD-MM-YYYY')
      // params.surgery_date = this.moment(params.surgery_date).format(
      //   'DD-MM-YYYY'
      // )
      // // if (file) params.file = file
      // const formData = new FormData()
      // for (var key in params) {
      //   formData.append(key, params[key] || '')
      // }
      const res = await self.$rf
        .getRequest('DoctorRequest')
        .createMdtOperation(params)

      if (res.data) {
        await self.savePdfToS3(base64, res.data?.id).finally(() => {
          self.$toast.open({
            message: 'Lưu thành công',
            type: 'success'
          })
          self.loading = false
          self.$emit('refresh')
        })
      }
    },
    async updateMdtDiseaseOperation (base64 = null) {
      const self = this

      if (!self.curr_disease.operation) return

      const params = { ...this.form }

      params.mdt_room_disease_id = self.curr_disease.id
      params.mdt_time = params.mdt_time
        ? this.moment(params.mdt_time).format('YYYY-MM-DDTHH:mm:ssZ')
        : null
      params.surgery_date = params.surgery_date
        ? this.moment(params.surgery_date)
          .add(7, 'hours')
          .format('YYYY-MM-DDTHH:mm:ssZ')
        : null

      params.treatment_time = params.treatment_time
        ? this.moment(params.treatment_time).format('YYYY-MM-DDTHH:mm:ssZ')
        : null

      params.treatment_time_end = params.treatment_time_end
        ? this.moment(params.treatment_time_end).format('YYYY-MM-DDTHH:mm:ssZ')
        : null

      params.treatment_time_start = params.treatment_time_start
        ? this.moment(params.treatment_time_start).format(
          'YYYY-MM-DDTHH:mm:ssZ'
        )
        : null

      // if (file) params.file = file

      // const formData = new FormData()

      // for (var key in params) {
      //   formData.append(key, params[key] || '')
      // }

      const res = await self.$rf
        .getRequest('DoctorRequest')
        .updateMdtOperation(self.curr_disease.operation.id, params)

      if (res.status === 200) {
        await self
          .savePdfToS3(base64, self.curr_disease.operation.id)
          .finally(() => {
            self.$toast.open({
              message: 'Lưu thành công',
              type: 'success'
            })
            self.loading = false
            self.$emit('refresh')
          })
      }
    },
    getAges (date) {
      return mdtUtils.getAges(new Date(date))
    },
    getGender (data) {
      return mdtUtils.getGender(data)
    },
    async handleSignTTTQM () {
      const params = {
        id: this.curr_disease?.operation?.id,
        type: SIGN_MDT_DISEASE.TTTQM,
        method: 1
      }

      const r = confirm(this.$t('mdt_room.lbl_approve_confirm_msg'))

      if (!r) return

      try {
        await this.$rf.getRequest('DoctorRequest').signMdtDiseaseDetail(params)

        this.$toast.open({
          message: this.$t('mdt_room.lbl_signed_msg'),
          type: 'success'
        })
        this.$emit('refresh')
      } catch (error) {
        console.log({ error })
      }
    },
    async handleGetTTTQM (curr_disease) {
      let clonedDisease = { ...curr_disease }
      try {
        if (!curr_disease?.operation?.id) {
          this.processDisease(clonedDisease)

          return
        }
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getMdtOperationByID(curr_disease?.operation?.id)

        clonedDisease = {
          ...curr_disease,
          operation: response.data
        }

        this.processDisease(clonedDisease)
      } catch (error) {
        console.log(error)
      }
    },
    async getDoctors () {
      try {
        const clinic_id = this.curr_disease?.medical_record?.clinic_id
        if (!clinic_id) return

        const res = await this.$rf
          .getRequest('DoctorRequest')
          .getDoctorsOfWorkspace(clinic_id, { limit: 1000 })
        this.doctors = res.data?.data
      } catch (error) {
        console.log(error)
      }
    },
    handleChangeDoctorType (item, key) {
      const doctorInfo =
        this.doctors?.find((doctor) => doctor?.user_id === item) || {}
      this.form[key] = doctorInfo?.name
    }
  }
}
</script>

<style scoped>
.robo-18-500 {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0em;
}

.robo-14-400 {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
}

.robo-14-500 {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0em;
}

.robo-16-500 {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0em;
}

.fs-20 {
  font-size: 20px;
}

.whitespace-nowrap {
  white-space: nowrap !important;
}

.w-full {
  width: 100% !important;
}
</style>
