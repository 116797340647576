<template>
  <div>
    <div
      class="modal fade"
      id="modalAddBienBan"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalAddBienBanLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" role="document" v-if="curr_disease">
        <div class="modal-content p-3">
          <div class="d-flex justify-content-between pb-3 border-bottom mb-3">
            <h5 class="modal-title" id="modalAddBienBanLabel">
              {{ $t("mdt_room.lbl_mdt_report") }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="mb-3">
            <div class="row">
              <div class="col-md-6">
                <p class="fs-14 mb-2">
                  {{ $t("mdt_room.lbl_patients_name") }}:
                  <span class="fw-700">{{
                    curr_disease && curr_disease.patient_name
                  }}</span>
                </p>
                <p class="fs-14 mb-2">
                  {{ $t("mdt_room.lbl_address") }}:
                  <span>{{ form.address }}</span>
                </p>
                <div class="d-flex">
                  <p class="fs-14 mb-2 mr-2">
                    {{ $t("mdt_room.lbl_diagnose") }}:
                  </p>
                  <div v-html="patientDiagnostic" class="fs-14 fw-400"></div>
                </div>
                <p class="fs-14 mb-2">
                  {{ $t("mdt_room.lbl_mems_preside") }}:
                  <span>{{ form.preside }}</span>
                </p>
              </div>
              <div class="col-md-6">
                <div class="d-flex justify-content-between">
                  <p class="fs-14 mb-2" v-if="!curr_disease.alias">
                    {{ $t("mdt_room.lbl_age") }}:
                    <span class="fw-700">
                      {{
                        curr_disease &&
                        curr_disease.patient_birthday &&
                        getAges(new Date(curr_disease.patient_birthday))
                      }}
                    </span>
                  </p>
                  <p class="fs-14 mb-2" v-else>
                    {{ $t("mdt_room.lbl_age") }}:
                    <span class="fw-700">
                      {{
                        curr_disease &&
                        curr_disease.alias &&
                        getAges(curr_disease.alias.alias_age)
                      }}
                    </span>
                  </p>
                  <p class="fs-14 mb-2">
                    {{ $t("mdt_room.lbl_genders") }}:
                    <span class="fw-700">
                      {{
                        curr_disease &&
                        curr_disease.patient_gender &&
                        getGender(curr_disease.patient_gender)
                      }}
                    </span>
                  </p>
                </div>
                <div class="d-flex align-items-center mb-2">
                  <p class="fs-14 mb-0 me-2 w-50">
                    {{ $t("mdt_room.lbl_mdt_at") }}:
                  </p>
                  <input
                    type="datetime-local"
                    class="border fs-14 form-control bg-white"
                    v-model="form.mdt_time"
                    :readonly="hasSigned"
                  />
                </div>
                <p class="fs-14 mb-2">
                  {{ $t("mdt_room.lbl_mems_type_secretary") }}:
                  <span>{{ form.secretary }}</span>
                </p>
              </div>
              <div class="col-12 d-flex mb-2">
                <div class="w-150px">
                  <p class="fs-14 text-nowrap mb-0 me-2">
                    {{ $t("mdt_room.lbl_participant") }}:
                  </p>
                </div>
                <div class="w--150px">
                  <textarea
                    class="form-control fs-14 bg-white border"
                    v-model="form.members"
                    rows="2"
                    :readonly="hasSigned"
                  ></textarea>
                </div>
              </div>
              <!-- <div class="col-12 d-flex align-items-center">
                <div class="w-150px">
                  <p
                    class="fs-14 mb-0 me-2 text-nowrap"
                  >{{ $t('mdt_room.lbl_pid') }}:</p>
                </div>
                <div class="w--150px">
                  <input
                    type="text"
                    class="border bg-white h-100 fs-14 w-100 form-control"
                    v-model="form.profile_code"
                  />
                </div>
              </div>-->
              <div class="col-4 mb-2">
                <div class="fs-14 mb-0 me-2 text-nowrap">
                  {{ $t("mdt_room.lbl_pid") }}:
                </div>
                <div>
                  <input
                    type="text"
                    class="border fs-14 form-control bg-white"
                    v-model="form.profile_code"
                    :readonly="!isOwner || hasSigned"
                  />
                </div>
              </div>
              <div class="col-8">
                <div class="fs-14 mb-0 me-2 text-nowrap">
                  {{ $t("mdt_room.lbl_report_treatment_time") }}:
                </div>
                <div class="row">
                  <div class="col-6">
                    <div>
                      <input
                        type="datetime-local"
                        class="border fs-14 form-control bg-white"
                        v-model="form.treatment_time_start"
                        :readonly="!isOwner || hasSigned"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div>
                      <input
                        type="datetime-local"
                        class="border fs-14 form-control bg-white"
                        v-model="form.treatment_time_end"
                        :readonly="!isOwner || hasSigned"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-100 d-flex justify-content-end mt-3">
            <div class="d-flex align-items-center">
              <div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="me-2"
                >
                  <path
                    d="M21.41 8.64C21.41 8.64 21.41 8.64 21.41 8.59C20.7053 6.66623 19.4269 5.00529 17.7474 3.83187C16.068 2.65845 14.0687 2.02917 12.02 2.02917C9.9712 2.02917 7.97189 2.65845 6.29246 3.83187C4.61304 5.00529 3.33455 6.66623 2.62995 8.59C2.62995 8.59 2.62995 8.59 2.62995 8.64C1.84308 10.8109 1.84308 13.1891 2.62995 15.36C2.62995 15.36 2.62995 15.36 2.62995 15.41C3.33455 17.3338 4.61304 18.9947 6.29246 20.1681C7.97189 21.3416 9.9712 21.9708 12.02 21.9708C14.0687 21.9708 16.068 21.3416 17.7474 20.1681C19.4269 18.9947 20.7053 17.3338 21.41 15.41C21.41 15.41 21.41 15.41 21.41 15.36C22.1968 13.1891 22.1968 10.8109 21.41 8.64ZM4.25995 14C3.91318 12.6892 3.91318 11.3108 4.25995 10H6.11995C5.95998 11.3285 5.95998 12.6715 6.11995 14H4.25995ZM5.07995 16H6.47995C6.71467 16.8918 7.05016 17.7541 7.47995 18.57C6.49925 17.9019 5.67945 17.0241 5.07995 16ZM6.47995 8H5.07995C5.67082 6.97909 6.48014 6.10147 7.44995 5.43C7.03051 6.24725 6.70509 7.10942 6.47995 8ZM11 19.7C9.77172 18.7987 8.9091 17.4852 8.56995 16H11V19.7ZM11 14H8.13995C7.95334 12.6732 7.95334 11.3268 8.13995 10H11V14ZM11 8H8.56995C8.9091 6.51477 9.77172 5.20132 11 4.3V8ZM18.92 8H17.52C17.2852 7.10816 16.9497 6.24594 16.52 5.43C17.5007 6.09807 18.3205 6.97594 18.92 8ZM13 4.3C14.2282 5.20132 15.0908 6.51477 15.43 8H13V4.3ZM13 19.7V16H15.43C15.0908 17.4852 14.2282 18.7987 13 19.7ZM15.86 14H13V10H15.86C16.0466 11.3268 16.0466 12.6732 15.86 14ZM16.55 18.57C16.9797 17.7541 17.3152 16.8918 17.55 16H18.95C18.3505 17.0241 17.5307 17.9019 16.55 18.57ZM19.74 14H17.88C17.9613 13.3365 18.0014 12.6685 18 12C18.0011 11.3315 17.961 10.6636 17.88 10H19.74C20.0867 11.3108 20.0867 12.6892 19.74 14Z"
                    fill="#41464B"
                  />
                </svg>
              </div>
              <span class="me-2">{{ nationString }}</span>
              <div :class="hasSigned ? '' : 'dropdown'">
                <a
                  href="javascript:;"
                  class="txt-pri text-decoration-none"
                  id="dropdownAddLang1"
                  :data-bs-toggle="hasSigned ? '' : 'dropdown'"
                  aria-expanded="false"
                  >+ Add</a
                >
                <ul class="dropdown-menu" aria-labelledby="dropdownAddLang1">
                  <li
                    v-for="n in appUtils.getNations()"
                    :key="n.id + '-nations'"
                  >
                    <a
                      class="dropdown-item"
                      href="javascript:;"
                      @click="addMultiLangItem(n.id)"
                      >{{ n.name }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- <div class="mb-3" v-for="(item, i) in form.multilang" :key="'treatment_time' + i">
            <p class="fs-14">
              <span>{{ $t('mdt_room.lbl_report_treatment_time') }}</span>
              <span v-if="item.lang !== langCode">({{ $t(`common.${getLangLocal(item.lang)}`) }})</span>
            </p>
            <input
              type="text"
              v-model="item.treatment_time"
              class="border form-control bg-white"
              :placeholder="$t('mdt_room.lbl_report_treatment_time')"
              :readonly="!isOwner"
            />
          </div>-->
          <div
            class="mb-3"
            v-for="(item, i) in form.multilang"
            :key="'treatment_progress' + i"
          >
            <p class="fs-14">
              <span>{{ $t("mdt_room.lbl_report_treatment_progress") }}</span>
              <span v-if="item.lang !== langCode"
                >({{ $t(`common.${getLangLocal(item.lang)}`) }})</span
              >
            </p>
            <vue-editor
              :disabled="hasSigned"
              v-model="item.treatment_progress"
              :editorToolbar="customToolbar"
            ></vue-editor>
          </div>
          <div
            class="mb-3"
            v-for="(item, i) in form.multilang"
            :key="'conclusion' + i"
          >
            <p class="fs-14">
              <span>{{ $t("mdt_room.lbl_report_treatment_conclusion") }}</span>
              <span v-if="item.lang !== langCode"
                >({{ $t(`common.${getLangLocal(item.lang)}`) }})</span
              >
              <span class="text-danger">*</span>
            </p>
            <vue-editor
              :disabled="hasSigned"
              v-model="item.conclusion"
              :editorToolbar="customToolbar"
            ></vue-editor>
          </div>
          <div
            class="mb-3"
            v-for="(item, i) in form.multilang"
            :key="'treatment' + i"
          >
            <p class="fs-14">
              <span>{{ $t("mdt_room.lbl_report_treatment_decision") }}</span>
              <span v-if="item.lang !== langCode"
                >({{ $t(`common.${getLangLocal(item.lang)}`) }})</span
              >
              <span class="text-danger">*</span>
            </p>
            <vue-editor
              :disabled="hasSigned"
              v-model="item.treatment"
              :editorToolbar="customToolbar"
            ></vue-editor>
          </div>
          <div
            class="mb-3 d-flex align-items-center"
            :class="
              curr_disease.concluding && curr_disease.concluding.sign
                ? 'justify-content-between'
                : 'justify-content-end'
            "
          >
            <div
              class="d-flex flex-wrap fs-14"
              v-if="curr_disease.concluding && curr_disease.concluding.sign"
            >
              <div>{{ curr_disease.concluding.signer_name }} đã ký -&nbsp;</div>
              <div>{{ signAt }}</div>
            </div>
            <div class="d-flex" v-if="curr_disease.status < 2">
              <button
                :disabled="!isOwner || loading || curr_disease.status === 2"
                class="btn btn-action btn-primary bg-pri bd-pri pl-2"
                @click="submit()"
              >
                <span
                  class="spinner-border spinner-border-sm me-2"
                  role="status"
                  v-if="loading"
                ></span>
                <span v-else>{{ $t("mdt_room.btn_save") }}</span>
              </button>
              <!-- <button
                :disabled="!isMaster || loading || !curr_disease.concluding || curr_disease.status === 2"
                class="btn btn-action btn-primary bg-pri bd-pri pl-2"
                @click="onUpdateStatus(2)"
              >
                <span class="spinner-border spinner-border-sm me-2" role="status" v-if="loading"></span>
                <span v-else>{{ $t('mdt_room.lbl_approve') }}</span>
              </button> -->
            </div>
            <div class="d-flex" v-if="curr_disease.status === 2">
              <div class="dropup">
                <button
                  v-if="
                    curr_disease.concluding && !curr_disease.concluding.sign
                  "
                  :disabled="loading"
                  class="btn-action btn btn-primary bg-pri bd-pri text-white"
                  id="dropdownMenuButton2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span
                    class="spinner-border spinner-border-sm me-2"
                    role="status"
                    v-if="loading"
                  ></span>
                  <span v-else>{{ $t("mdt_room.btn_sign") }}</span>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                  <li
                    v-for="(item, i) in this.form.multilang"
                    :key="'sign' + i"
                    @click="openModalSignPDF(item)"
                  >
                    <a
                      class="dropdown-item"
                      href="javascript:;"
                      :set="(nation = appUtils.getNation(item.lang))"
                    >
                      {{ nation && nation.name }}
                    </a>
                  </li>
                </ul>
              </div>
              <!-- <button
                :disabled="!isOwner|| loading || !curr_disease.concluding"
                class="btn-action btn bg-pri bd-pri text-white"
                @click="onUpdateStatus(0)"
              >
                <span class="spinner-border spinner-border-sm me-2" role="status" v-if="loading"></span>
                <span v-else>{{ $t("mdt_room.btn_cancel_approve") }}</span>
              </button> -->
            </div>
            <div class="dropup">
              <button
                class="btn btn-action btn-primary bg-pri bd-pri pl-2"
                :disabled="loading || !curr_disease.concluding"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span
                  class="spinner-border spinner-border-sm me-2"
                  role="status"
                  v-if="loading"
                ></span>
                <span v-else>{{ $t("mdt_room.lbl_print") }}</span>
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li
                  v-for="(item, i) in this.form.multilang"
                  :key="'print' + i"
                  @click="clickBB(item.lang)"
                >
                  <a
                    class="dropdown-item"
                    href="javascript:;"
                    :set="(nation = appUtils.getNation(item.lang))"
                  >
                    {{ nation && nation.name }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TempBienBan
      :form="form"
      :save_pdf="save_pdf_count"
      @exportError="loading = false"
      @onSubmitting="onSubmitting"
      :mdt_disease="curr_disease"
      :checkin_members="checkin_members"
      :room_info="room_info"
      :print="print"
      :printLang="printLang"
      :multiLangData="multiLangData"
    />

    <ModalSignPDF
      v-if="pdfViewerID"
      :ref="`${pdfViewerID}_MDTModalSendSign`"
      :containerIDProps="`${pdfViewerID}_MDTContainerSendSignID`"
      :elementIDProps="`${pdfViewerID}_MDTSendSignID`"
      :imageIDProps="`${pdfViewerID}_MDTSendSignImageID`"
      @onSuccess="handleSignDocumentSuccess"
    />
    <PdfViewer
      v-if="pdfViewerID"
      :ref="`${pdfViewerID}_MdtPdfViewer`"
      :containerIDProps="`${pdfViewerID}_ContainerMdtPDFViewerID`"
      :elementIDProps="`${pdfViewerID}_MdtPDFViewerID`"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import mdtUtils from '../../utils/mdtUtils'
import TempBienBan from '../../templates/BienBan/TempBienBan.vue'
import appUtils from '../../utils/appUtils'
import { VueEditor } from 'vue2-editor'
import { mapState } from 'vuex'
import {
  ENVELOPE_DOCUMENT_TYPE,
  // SIGN_ENVELOPE_STATUS,
  SIGN_MDT_DISEASE
} from '../../utils/constants'
import SignatureRequest from '../../api/request/SignatureRequest'
import ModalSignPDF from '../SignDocument/ModalSignPDF.vue'
import uploadS3File from '../../utils/uploadS3File'
import PdfViewer from '../SignDocument/PdfViewer.vue'

export default {
  name: 'BienBan',
  props: [
    'curr_disease',
    'room_info',
    'checkin_members',
    'not_update_status',
    'present_language',
    'room_members'
  ],
  components: { TempBienBan, VueEditor, ModalSignPDF, PdfViewer },
  data () {
    return {
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['code-block', 'link'],
        [
          {
            align: ''
          },
          {
            align: 'center'
          },
          {
            align: 'right'
          },
          {
            align: 'justify'
          }
        ]
      ],
      form: {
        address: '',
        treatment_time: '',
        treatment_time_start: '',
        treatment_time_end: '',
        treatment_progress: '',
        conclusion: '',
        treatment: '',
        mdt_time: appUtils.formatInputDateTime() || '',
        members: '',
        profile_code: '',
        preside: '',
        secretary: '',
        multilang: [],
        admission_room: '',
        admission_bed: ''
      },
      mdtUtils,
      doctor_id: null,
      save_pdf_count: 0,
      print: 0,
      loading: false,
      update_status: false,
      dtr_member: null,
      pre: null,
      printLang: 1,
      multilangItem: {
        lang: 1,
        diagnostic: '',
        treatment_time: '',
        treatment_progress: '',
        conclusion: '',
        treatment: ''
      },
      appUtils,
      pdfViewerID: null,
      mdtEnvelope: []
    }
  },
  computed: {
    ...mapState({
      person: (state) => state.person
    }),
    langId () {
      return appUtils.getLangId(this.present_language || 'vi')
    },
    langCode () {
      const lang = appUtils.getNationByCode(this.$lang || 'vi')
      return lang.id
    },
    langCodePresent () {
      return this.present_language || 'vi'
    },
    isMaster () {
      return (
        this.dtr_member?.role === 1 ||
        this.dtr_member?.role === 4 ||
        this.isOwner
      )
    },
    isPresident () {
      return this.doctor_id === this.room_info?.preside?.doctor_id
    },
    isOwner () {
      // return this.doctor_id === this.curr_disease?.doctor_id
      return true
    },
    patientDiagnostic () {
      if (this.curr_disease) {
        if (this.curr_disease.multilang?.length) {
          const langId = this.langId
          const r = this.curr_disease.multilang.find(
            (item) => item.lang === langId
          )
          if (!r) return this.curr_disease.patient_diagnostic
          else return r.patient_diagnostic
        }
        return ' ' + this.curr_disease.patient_diagnostic
      }
      return ''
    },
    nationString () {
      let string = ''
      this.form?.multilang &&
        this.form.multilang.forEach((item) => {
          const nation = appUtils.getNation(item.lang)
          if (nation) string += nation.name + ', '
        })
      if (string.length) string = string.trim().slice(0, -1)
      return string
    },
    currentNation () {
      return appUtils.getNationByCode(this.present_language || 'vi')
    },
    multiLangData () {
      return this.form.multilang
    },
    signAt () {
      return window
        .moment(this.curr_disease?.concluding?.sign?.sign_at)
        .format('HH:mm - DD/MM/YYYY')
    },
    hasSigned () {
      return (
        Boolean(this.curr_disease) &&
        Boolean(
          this.curr_disease.concluding?.sign ||
            this.curr_disease.operation?.sign ||
            this.curr_disease.status === 2
        )
      )
    }
  },
  watch: {
    curr_disease (d) {
      this.handlerDisease(d)
    },
    checkin_members (members) {
      this.handlerMemberstring()
    },
    room_info (r) {
      this.handlerRoomInfo()
    },
    present_language (l) {
      const langId = appUtils.getLangId(l || 'vi')
      this.printLang = langId || 1
    },
    person (data) {
      if (data) {
        this.form.admission_room = data.admission_room || ''
        this.form.admission_bed = data.admission_bed || ''
      } else {
        this.form.admission_room = ''
        this.form.admission_bed = ''
      }
    }
  },
  mounted () {
    this.doctor_id = this.$user?.doctor.id
    const langId = appUtils.getLangId(this.present_language || 'vi')
    this.printLang = langId || 1
    this.handlerMemberstring()
    this.handlerRoomInfo()
  },
  methods: {
    getLangLocal (langId) {
      const lang = appUtils.getNation(langId)
      return lang.code
    },
    handlerRoomInfo () {
      if (!this.room_info) return
      this.dtr_member = this.room_info?.members?.find(
        (m) => m.doctor_id === this.doctor_id
      )
      this.pre = this.room_info?.members?.find((m) => m.role === 1)
      this.form.preside = this.pre?.doctor?.name
    },
    handlerDisease (d) {
      const langId = this.langId

      if (d) {
        let _tempMultiLang = []

        if (d?.medical_record) {
          this.form.profile_code = d?.medical_record?.ref_id
        }

        if (d.concluding) {
          this.form.address = d.concluding.address
          this.form.treatment_time = d.concluding.treatment_time || null
          this.form.treatment_time_start = d.concluding.treatment_time_start
            ? appUtils.formatInputDateTime(d.concluding.treatment_time_start)
            : ''
          this.form.treatment_time_end = d.concluding.treatment_time_end
            ? appUtils.formatInputDateTime(d.concluding.treatment_time_end)
            : ''
          this.form.treatment_progress =
            d.concluding.treatment_progress || null
          this.form.conclusion = d.concluding.conclusion || null
          this.form.treatment = d.concluding.treatment || null
          this.form.multilang = d.concluding.multilang
          if (!this.form.multilang?.length) this.addMultiLangItem(langId)
          if (d.concluding.members) this.form.members = d.concluding.members
          this.form.profile_code = d.concluding.profile_code || ''
          this.form.preside =
            d.concluding.preside || d.preside || this.pre?.doctor?.name || ''
          this.form.secretary = d.concluding.secretary || d.doctor?.name || ''

          this.form.mdt_time = d.concluding.mdt_time
            ? appUtils.formatInputDateTime(d.concluding.mdt_time)
            : appUtils.formatInputDateTime(this.room_info?.start_time)
        } else {
          this.form.address = d.patient_address
          this.form.treatment_time = ''
          this.form.mdt_time = d.mdt_date
            ? appUtils.formatInputDateTime(d.mdt_date)
            : appUtils.formatInputDateTime()
          this.form.treatment_time_start = d.admission_date
            ? appUtils.formatInputDateTime(d.admission_date)
            : ''
          this.form.treatment_time_end = d.mdt_date
            ? appUtils.formatInputDateTime(d.mdt_date)
            : ''
          this.form.treatment_progress = d.patient_diseases_current_cure
          this.form.conclusion = d.conclusion
          this.form.treatment = d.treatment
          this.form.profile_code =
            d?.medical_record?.ref_id || d?.operation?.code || ''
          this.form.preside = d.preside || this.pre?.doctor?.name || ''
          this.form.secretary = d.doctor?.name || ''

          if (d.multilang.length) {
            _tempMultiLang = d.multilang.map((x) => ({
              lang: x.lang,
              diagnostic: x.patient_diagnostic,
              // treatment_time: '',
              treatment_progress: x.patient_diseases_current_cure,
              conclusion: x.conclusion,
              treatment: x.treatment
            }))
          } else {
            console.log(d)
            const obj = {
              lang: langId,
              diagnostic: d?.patient_diagnostic || '',
              treatment_time: d?.treatment_time || '',
              treatment_progress:
                d?.operation?.medical_history ||
                d?.patient_diseases_current_cure ||
                '',
              conclusion: d?.operation?.conclusion || d?.conclusion || '',
              treatment: d?.treatment || ''
            }
            _tempMultiLang.push(obj)
          }

          this.form.multilang = _tempMultiLang
          this.addMultiLangItem(langId)
        }
      } else {
        this.form = {
          address: '',
          treatment_time: '',
          treatment_time_start: '',
          treatment_time_end: '',
          treatment_progress: '',
          conclusion: '',
          treatment: '',
          mdt_time: appUtils.formatInputDateTime(),
          members: '',
          profile_code: '',
          preside: '',
          secretary: '',
          multilang: []
        }
      }
    },
    addMultiLangItem (id) {
      if (!id) return
      const exist = this.form.multilang.find((item) => item.lang === id)
      if (exist) return
      if (this.curr_disease?.multilang?.length) {
        const found = this.curr_disease?.multilang.find(
          (item) => item.lang === id
        )
        if (found) {
          console.log(found)
          const result = {
            lang: id,
            diagnostic: found.patient_diagnostic,
            treatment_time: '',
            treatment_progress: found.patient_diseases_current_cure,
            conclusion: found.conclusion,
            treatment: found.treatment
          }
          this.form.multilang.push(result)
          return
        }
      }
      this.form.multilang.push({
        lang: id,
        diagnostic: '',
        treatment_time: '',
        treatment_progress: '',
        conclusion: '',
        treatment: ''
      })
    },
    handlerMemberstring () {
      const members = this.checkin_members
      if (members && members.count && !this.form.members) {
        let string = ''
        members.data.forEach((m) => {
          if (m.status === 1) {
            string += m?.doctor?.name + ', '
          }
        })
        this.form.members = string
      }
    },

    submit (update) {
      let isError = false

      if (
        update &&
        this.curr_disease.type === 1 &&
        !this.curr_disease.operation
      ) {
        isError = true
        alert('Cần có đủ Tóm tắt thông qua mổ, vui lòng cập nhật')
        return
      }

      if (!this.form?.multilang?.length) {
        isError = true
        alert(this.$t('invalid_save_concluding', this.present_language))
        return
      } else {
        for (let index = 0; index < this.form.multilang.length; index++) {
          const item = this.form.multilang[index]
          if (!item.conclusion?.length || !item.treatment?.length) {
            isError = true
          }
        }
      }

      if (isError) {
        alert(this.$t('invalid_save_concluding', this.present_language))
        return false
      }

      this.printLang = this.langId
      setTimeout(() => {
        this.update_status = update
        this.save_pdf_count++
      }, 100)
    },
    formatHmDMY (date) {
      const string = window.moment(date).format('HH:mm DD/MM/YYYY')
      string.split(' ').join(' ngày ')
      return string
    },

    onSubmitting (base64) {
      if (!this.curr_disease) return
      if (this.curr_disease?.concluding) {
        this.mdtConcludingUpdate(base64)
      } else {
        this.mdtConcludingCreate(base64)
      }
    },

    setFormData () {
      let self = this
      let formData = new FormData()

      const params = {
        name: self.curr_disease.patient_name,
        age:
          self.curr_disease?.alias &&
          self.curr_disease?.alias.status &&
          self.curr_disease?.alias.alias_age
            ? self.getAges(self.curr_disease?.alias.alias_age)
            : self.curr_disease?.patient_birthday
              ? self.getAges(new Date(self.curr_disease?.patient_birthday))
              : null,
        sex: self.curr_disease.patient_gender,
        address: self.curr_disease.patient_address,
        mdt_room_disease_id: self.curr_disease.id,
        profile_code: self.form.profile_code || '',
        preside: self.curr_disease.preside,
        secretary: self.curr_disease.secretary,
        treatment_time: self.form.treatment_time,
        treatment_time_start: self.form.treatment_time_start,
        treatment_time_end: self.form.treatment_time_end,
        treatment_progress: self.form.treatment_progress,
        conclusion: self.form.conclusion,
        treatment: self.form.treatment,
        multilang: self.form.multilang,
        mdt_time: self.form.mdt_time
          ? window.moment(self.form.mdt_time).format('HH:mm DD-MM-YYYY')
          : window.moment().format('HH:mm DD-MM-YYYY')
      }

      const newMultiLang = self.form.multilang?.map((value, index) => {
        let data = {
          mdt_room_disease_concluding_id:
            self.curr_disease.concluding?.id || null,
          ...value
        }

        return data
      })

      for (var key in params) {
        if (key !== 'multilang') {
          formData.append(key, params[key] || '')
        } else if (params[key]?.length) {
          params[key].forEach((ml, i) => {
            typeof ml.lang !== 'undefined' &&
              formData.append(`multilang[${i}][lang]`, ml.lang)
            typeof ml.mdt_room_disease_concluding_id !== 'undefined' &&
              formData.append(
                `multilang[${i}][mdt_room_disease_concluding_id]`,
                ml.mdt_room_disease_concluding_id
              )
            ml.diagnostic &&
              formData.append(`multilang[${i}][diagnostic]`, ml.diagnostic)
            ml.treatment_time &&
              formData.append(
                `multilang[${i}][treatment_time]`,
                ml.treatment_time
              )
            ml.treatment_time_start &&
              formData.append(
                `multilang[${i}][treatment_time_start]`,
                ml.treatment_time_start
              )
            ml.treatment_time_end &&
              formData.append(
                `multilang[${i}][treatment_time_end]`,
                ml.treatment_time_end
              )
            ml.treatment_progress &&
              formData.append(
                `multilang[${i}][treatment_progress]`,
                ml.treatment_progress
              )
            ml.conclusion &&
              formData.append(`multilang[${i}][conclusion]`, ml.conclusion)
            ml.treatment &&
              formData.append(`multilang[${i}][treatment]`, ml.treatment)
          })
        }
      }

      return [formData, newMultiLang]
    },

    async savePDF ({ multilang, base64 }) {
      const self = this
      if (!multilang.length || !base64.length) return

      try {
        const formData = new FormData()

        _.forEach(multilang, (value, index) => {
          typeof value.mdt_room_disease_concluding_id !== 'undefined' &&
            formData.append(
              `multilang[${index}][object_id]`,
              value?.mdt_room_disease_concluding_id
            )
          formData.append(`multilang[${index}][object_type]`, 1)
          formData.append(`multilang[${index}][lang]`, value.lang)
          formData.append(`multilang[${index}][content]`, base64[index])
        })

        await self.$rf.getRequest('DoctorRequest').setEmrDocument(formData)
      } catch (error) {
        console.log(error)
      }
    },

    async mdtConcludingCreate (base64 = null) {
      const self = this

      try {
        this.loading = true

        const [formData, newMultiLang] = self.setFormData()
        const res = await self.$rf
          .getRequest('DoctorRequest')
          .mdtConcludingCreate(formData)

        if (res.data) {
          let tempNewMultiLang = []
          _.forEach(newMultiLang, (value) => {
            tempNewMultiLang.push({
              ...value,
              mdt_room_disease_concluding_id: res.data.id
            })
          })
          await self
            .savePDF({
              multilang: tempNewMultiLang,
              base64
            })
            .finally(() => {
              this.$toast.open({
                message: this.$t('save_success'),
                type: 'success'
              })
              self.$emit('refresh')
            })
        }

        this.loading = false
        self.$emit('refresh')
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    async mdtConcludingUpdate (base64 = null) {
      let self = this

      try {
        this.loading = true

        const [formData, newMultiLang] = self.setFormData()
        const res = await self.$rf
          .getRequest('DoctorRequest')
          .mdtConcludingUpdate(self.curr_disease.concluding.id, formData)

        if (res.data) {
          await self
            .savePDF({
              multilang: newMultiLang,
              base64
            })
            .finally(() => {
              self.$toast.open({
                message: this.$t('save_success'),
                type: 'success'
              })
            })
        }

        this.loading = false
        self.$emit('refresh')
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    async updateMdtDiseases (id) {
      // const self = this
      // const params = {
      //   mdt_room_id: self.curr_disease.mdt_room_id,
      //   treatment_progress: self.form.treatment_progress,
      //   conclusion: self.form.conclusion,
      //   treatment: self.form.treatment,
      //   type: self.curr_disease.type ? 1 : 0,
      //   date: self.curr_disease.mdt_date
      // }
      // await self.$rf
      //   .getRequest('DoctorRequest')
      //   .updateMdtDiseases(id, params)
      //   .finally(() => {
      //     return true
      //   })
    },
    async onUpdateStatus (stt) {
      const self = this

      if (
        !self.curr_disease ||
        !self.curr_disease?.concluding ||
        !self.curr_disease?.concluding?.multilang
      ) {
        // alert(
        //   this.$t('mdt_room.lbl_approve_mdt_case_msg', this.present_language)
        // )
        this.$toast.open({
          type: 'warning',
          message: this.$t('mdt_room.lbl_approve_mdt_case_msg')
        })
        return
      }

      const multilang = self.curr_disease?.concluding?.multilang
      let isError = false

      for (let i = 0; i < multilang.length; i++) {
        const item = multilang[i]

        if (!item?.conclusion?.length || !item?.treatment?.length) {
          isError = true
        }
      }

      if (isError) {
        alert(
          this.$t('mdt_room.lbl_savecase_required_msg', this.present_language)
        )
        return
      }

      if (!self.curr_disease.operation && self.curr_disease.type === 1) {
        alert(this.$t('mdt_room.lbl_save_smdt_msg', this.present_language))
        return
      }

      const r = confirm(this.$t('mdt_room.lbl_approve_confirm_msg'))

      if (!r) return

      await this.mdtDiseaseStatus(stt).then((res) => {
        this.$emit('refresh')
      })
    },
    async mdtDiseaseStatus (stt) {
      const self = this

      if (!self.curr_disease || _.isNaN(stt)) return

      const params = {
        status: stt
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .mdtDiseaseStatus(self.curr_disease.id, params)
        .finally(() => {
          self.$toast.open({
            message: this.$t('mdt_room.lbl_success'),
            type: 'success'
          })
        })
    },
    async finishCreateOrUpdate () {
      try {
        if (this.update_status && this.curr_disease.status !== 2) {
          await this.mdtDiseaseStatus(this.curr_disease.id, 2)
        }
        await this.updateMdtDiseases(this.curr_disease.id)
      } catch (error) {
        console.log(error)
      } finally {
        this.$toast.open({
          message: this.$t('save_success'),
          type: 'success'
        })
        this.loading = false
        this.$emit('refresh')
      }
    },
    getAges (value) {
      if (!value) return

      if (value instanceof Date) {
        let bd = window.moment(value, 'YYYY-MM-DD')
        let age = window.moment().diff(bd, 'years')
        return age
      } else {
        return value
      }
    },
    getGender (data) {
      return mdtUtils.getGender(data, this.present_language)
    },
    showBienBan (show) {
      window.$('#modalAddBienBan').modal(show ? 'show' : 'hide')
    },
    onPrint (lang) {
      this.loading = true
      this.printLang = lang
      setTimeout(() => {
        this.loading = false
      }, 3000)
      setTimeout(() => {
        this.print++
      }, 100)
    },
    clickBB (lang) {
      const self = this

      if (!self.curr_disease.concluding?.multilang?.length) {
        alert(this.$t('mdt_room.lbl_no_mom'))
        return
      }

      const multiLangData = self.curr_disease.concluding?.multilang
      const data = _.find(multiLangData, { lang })

      if (data?.file_name_printing) {
        this.openUrl({ urlFile: data.file_name_printing })
      }
    },
    async openUrl ({ urlFile, print = false }) {
      if (!urlFile) {
        this.$toast.open({
          message: this.$t('mdt_room.lbl_notfound'),
          type: 'error'
        })
        return
      }
      window.open(urlFile)

      // try {
      //   this.loading = true

      //   const res = await this.$rf.getRequest('DoctorRequest').getEmrDocument({
      //     fileName
      //   })
      //   if (res.data) {
      //     if (print) {
      //       const printWindow = window.open(res.data?.documentLink)
      //       printWindow.print()

      //       printWindow.onafterprint = function () {
      //         printWindow.close()
      //       }
      //     } else {
      //       window.open(res.data?.documentLink)
      //     }
      //   }
      //   this.loading = false
      // } catch (error) {
      //   console.log(error)
      //   this.loading = false
      // }
    },
    async handleSignBBHC () {
      const params = {
        id: this.curr_disease?.concluding?.id,
        type: SIGN_MDT_DISEASE.BBHC,
        method: 1
      }

      // const r = confirm(this.$t('mdt_room.lbl_approve_confirm_msg'))
      // if (!r) return

      try {
        await this.$rf.getRequest('DoctorRequest').signMdtDiseaseDetail(params)

        // this.$toast.open({
        //   message: this.$t('mdt_room.lbl_signed_msg'),
        //   type: 'success'
        // })
        this.$emit('refresh')
      } catch (error) {
        console.log({ error })
      }
    },
    async handleSignDocumentSuccess (_, envelopeData) {
      // try {
      //   if (!envelopeData?.id) return
      //   const request = new SignatureRequest()
      //   const response = await request.getSignEnvelopeByID(envelopeData?.id)
      //   console.log({ response })
      //   const envelopeStatusCompleted =
      //     response?.data?.status === SIGN_ENVELOPE_STATUS.completed
      //   if (envelopeStatusCompleted) {
      //     this.handleSignBBHC()
      //   }
      // } catch (error) {
      //   console.log(error)
      // }
    },
    async openModalSignPDF (item) {
      let error
      let signer

      const documentLink = item?.file_name_printing

      if (!documentLink) {
        this.$toast.open({
          message: 'Không tìm thấy tài liệu cần ký',
          type: 'error'
        })
        return
      }

      const envelopeInfo = await this.handleCheckSignEnvelope(item)

      if (envelopeInfo) return

      const procedure = await this.handleProcessProcedureData(
        (err, signerData) => {
          if (err) {
            error = true

            this.$toast.open({
              message: err,
              type: 'error'
            })
          } else {
            signer = signerData
          }
        }
      )

      if (error) return

      this.pdfViewerID = item?.id
      const mdtRef = `${this.pdfViewerID}_MDTModalSendSign`
      this.$nextTick(() => {
        this.$refs[mdtRef].handleOpen(
          documentLink,
          ENVELOPE_DOCUMENT_TYPE.MDT_FORM,
          Number(item.id),
          signer?.id,
          procedure?.signatories || [],
          null,
          procedure?.id
        )
      })

      // url,
      // docType,
      // documentID,
      // signerIDProp,
      // procedure,
      // personIDProp
    },
    async getSignProcedures () {
      try {
        const params = {
          page_num: 1,
          page_size: 1000,
          order: 'desc',
          sort_by: 'id',
          document_type: ENVELOPE_DOCUMENT_TYPE.MDT_FORM,
          ws_id: this.room_info?.clinic_id
        }

        const request = new SignatureRequest()

        const response = await request.getSignProcedures(params)
        return response.data?.data?.[0]
      } catch (error) {
        console.log(error)
      }
    },
    getUserByRole (role, callback) {
      let user
      let signer
      switch (role) {
        case 'secretary':
          const secretary = this.curr_disease?.doctor?.user
          console.log({ secretary })
          if (!secretary) {
            // eslint-disable-next-line standard/no-callback-literal
            callback('Không tìm thấy thông tin Thư ký')
          } else {
            user = secretary?.doctor?.user || {}
            if (user?.id === this.$user?.id && user?.id) {
              signer = user
              callback(null, signer)
            }
          }

          break
        case 'president':
          const president = this.room_members?.find((item) => item?.role === 1)

          if (!president) {
            // eslint-disable-next-line standard/no-callback-literal
            callback('Không tìm thấy thông tin Chủ toạ')
          } else {
            user = president?.doctor?.user || {}
            if (user?.id === this.$user?.id && user?.id) {
              signer = user
              callback(null, signer)
            }
          }
          break

        default:
          break
      }

      return user
    },
    async handleGetEnvelopeByDocumentID (item) {
      try {
        const request = new SignatureRequest()
        const response = await request.getSignEnvelopeByDocumentID({
          doc_id: item.id,
          doc_type: ENVELOPE_DOCUMENT_TYPE.MDT_FORM
        })

        return response.data
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },

    async handleProcessProcedureData (callback) {
      const procedure = await this.getSignProcedures()

      const signatories = procedure?.sign_nodes?.reduce((arr, node) => {
        const signs = node?.node_signatories?.map((item) => {
          const user = this.getUserByRole(item?.user_role, callback)

          return {
            ...item,
            user,
            user_id: user?.id
          }
        })

        return arr.concat(signs)
      }, [])

      return { ...procedure, signatories }
    },
    async handleCheckSignEnvelope (item) {
      try {
        const signEnvelope = await this.handleGetEnvelopeByDocumentID(item)
        if (!signEnvelope) return

        const signatories = signEnvelope?.envelope_signatories?.map((item) => ({
          ...item,
          user: item?.user_info,
          user_id: item?.user_id
        }))

        const signerInfo = signEnvelope?.envelope_signatories?.find(
          (item) => item?.user_id === this.$user?.id
        )

        const s3Url = await uploadS3File.getLinkVideoAWS(
          signEnvelope?.document_url
        )

        this.pdfViewerID = item?.id

        if (signerInfo?.status === 2) {
          const mdtRef = `${this.pdfViewerID}_MdtPdfViewer`

          this.$nextTick(() => {
            this.$refs[mdtRef].handleOpen(
              s3Url,
              ENVELOPE_DOCUMENT_TYPE.MDT_FORM,
              Number(item.id)
            )
          })
        } else {
          const mdtRef = `${this.pdfViewerID}_MDTModalSendSign`

          this.$nextTick(() => {
            this.$refs[mdtRef].handleOpen(
              s3Url,
              ENVELOPE_DOCUMENT_TYPE.MDT_FORM,
              Number(item.id),
              signerInfo?.user_id,
              signatories || [],
              signEnvelope
            )
          })
        }

        return signEnvelope
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped>
.mr-2 {
  margin-right: 4px;
}

.form-control {
  height: unset;
}

.btn-action {
  width: 150px;
  margin-left: 24px;
}

.fs-18 {
  font-size: 18px;
}

.w-150px {
  width: 150px;
}

.w--150px {
  width: calc(100% - 150px);
}

.form-control {
  border-radius: 0 !important;
}
</style>
