<template>
  <div style="mdt-info" v-if="mdt_room">
    <div class="mb-4 border-bottom d-flex justify-content-between header-info">
      <p class="mb-2 fw-500 fw-16">{{$t('mdt_room.lbl_location')}}</p>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click="hideInfo"
        class="c-pointer"
      >
        <path
          d="M6 17.9998L17.9998 6"
          stroke="#292D32"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.9998 17.9998L6 6"
          stroke="#292D32"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div>
      <p class="mb-2 fs-24 fw-600 txt-grey-900" v-if="mdt_room">
        {{ mdt_room.name }}
      </p>
      <!-- <p class="mb-4 fs-18 txt-black">
        {{ mdtUtils.getTimeStringDetail(mdt_room) }}
      </p> -->
      <div class="mb-3 row">
        <div class="mb-0 fs-16 txt-grey-900 col-4 align-items-center">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 2C8.13 2 5 5.13 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 5.13 15.87 2 12 2ZM7 9C7 6.24 9.24 4 12 4C14.76 4 17 6.24 17 9C17 11.88 14.12 16.19 12 18.88C9.92 16.21 7 11.85 7 9Z"
              fill="#1D2939"
            />
            <path
              d="M12 11.5C13.3807 11.5 14.5 10.3807 14.5 9C14.5 7.61929 13.3807 6.5 12 6.5C10.6193 6.5 9.5 7.61929 9.5 9C9.5 10.3807 10.6193 11.5 12 11.5Z"
              fill="#1D2939"
            />
          </svg>

          {{$t('mdt_room.lbl_location')}}
        </div>
        <p class="mb-0 fs-14 fw-500 col-8 txt-grey-900">
          {{ mdt_room.location }}...
        </p>
      </div>

      <div class="mb-3 row" :set="(link = mdtUtils.linkRoom(mdt_room.room))">
        <div class="mb-0 fs-16 txt-grey-900 col-4 align-items-center">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17 7H13V9H17C18.65 9 20 10.35 20 12C20 13.65 18.65 15 17 15H13V17H17C19.76 17 22 14.76 22 12C22 9.24 19.76 7 17 7ZM11 15H7C5.35 15 4 13.65 4 12C4 10.35 5.35 9 7 9H11V7H7C4.24 7 2 9.24 2 12C2 14.76 4.24 17 7 17H11V15ZM8 11H16V13H8V11Z"
              fill="black"
            />
          </svg>

          {{$t('mdt_room.lbl_room_link')}}
        </div>

        <p class="mb-0 fs-14 fw-500 col-8 max-line-1 txt-grey-900">
          {{ link || "..." }}
        </p>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <a
          href="javascript:;"
          @click="copyLink(link, langCode)"
          class="
            txt-pri
            fs-16
            fw-600
            d-flex
            align-items-center
            text-decoration-none
          "
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="me-2"
          >
            <path
              d="M16.5 1H4.5C3.4 1 2.5 1.9 2.5 3V17H4.5V3H16.5V1ZM19.5 5H8.5C7.4 5 6.5 5.9 6.5 7V21C6.5 22.1 7.4 23 8.5 23H19.5C20.6 23 21.5 22.1 21.5 21V7C21.5 5.9 20.6 5 19.5 5ZM19.5 21H8.5V7H19.5V21Z"
              fill="#20419B"
            />
          </svg>

          {{$t('mdt_room.btn_copy_link')}}
        </a>
      </div>
      <div class="mb-3">
        <div class="mb-3" v-if="room_members && room_members.length">
          <div
            v-for="mem in room_members"
            :key="mem.id + 'mem'"
            class="
              d-flex
              align-items-center
              justify-content-between
              item-doctor
              p-0
              mb-3
            "
            :set="(status = getStatus(mem.doctor_id))"
          >
            <div
            v-if="mem.doctor"
              class="d-flex align-items-center"
              :set="(user = mem.doctor.user)"
            >
              <span
                v-if="user && user.avatar"
                class="avatar avatar-sm avatar-rounded me-2"
                :style="
                  'background-image: url(' +
                  appUtils.getImageURL(user.avatar) +
                  ')'
                "
              ></span>
              <span
                v-if="user && !user.avatar"
                class="avatar avatar-sm avatar-rounded me-2"
              >
                {{ user && user.name ? user.name.charAt(0) : "" }}
              </span>
              <div>
                <p class="fs-14 txt-black mb-0 max-line-1">
                  {{ mem.doctor ? mem.doctor.name : "" }}
                </p>
                <p class="fs-12 text-black-50 mb-0 max-line-1">
                  {{ mdtUtils.getRoleName(mem.role)}}
                </p>
              </div>
            </div>
            <p class="mb-0 fs-14" :class="status ? status.class : ''">
              {{ status.text }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mdtUtils from '../../utils/mdtUtils'
import appUtils from '../../utils/appUtils'
const statusCheckin = [
  { stt: 0, text: 'mdt_room.lbl_sts_waiting', class: 'text-warning' },
  { stt: 1, text: 'mdt_room.lbl_sts_attended', class: 'text-success' },
  { stt: 2, text: 'mdt_room.lbl_sts_absent', class: 'text-danger' }
]
export default {
  name: 'MdtInfo',
  props: ['mdt_room', 'room_members', 'checkin_members', 'present_language'],
  data () {
    return {
      mdtUtils,
      appUtils,
      statusCheckin
    }
  },
  computed: {
    langCode () {
      console.log(this.present_language)
      return this.present_language || 'vi'
    }
  },
  methods: {
    getStatus (id, lang = null) {
      let status = 0
      if (
        this.checkin_members &&
        this.checkin_members?.data?.length
      ) {
        const checkin = this.checkin_members.data.find((c) => parseInt(c.doctor_id) === parseInt(id))
        if (checkin) {
          status = checkin.status
        }
      }
      const result = {
        stt: this.statusCheckin[status].stt,
        text: this.$t(this.statusCheckin[status].text, lang),
        class: this.statusCheckin[status].class
      }
      return result
    },
    copyLink (link, lang = null) {
      const url = document.createElement('input')
      document.body.appendChild(url)
      url.value = link
      url.select()
      url.setSelectionRange(0, 99999)
      document.execCommand('copy')
      this.$toast.open({
        message: this.$t('mdt_room.lbl_copied_msg', lang),
        type: 'success'
      })
      url.remove()
    },
    hideInfo () {
      this.$emit('hideInfo')
    }
  }
}
</script>

<style scoped>
.mdt-info {
  background: #fff;
  color: black;
}
.header-info {
  width: 100%;
}
</style>
